import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { QueryClientProvider, QueryClient } from "react-query";
import { CookiesProvider } from "react-cookie";
import ConfirmProvider from "contexts/ConfirmProvider";
import StackSliderProvider from "contexts/StackSlideProvider";
import ReportPopupProvider from "contexts/ReportPopupProvider";
import TalkPlusClientProvider from "contexts/TalkPlusClientContext";
import App from "./App";
import CategoryUnreadCountProvider from "contexts/CategoryUnreadCountProvider";
import ChannelListProvider from "contexts/ChannelListProvier";

import { mobileOS } from "utils/lib.ts";
import { STORAGE_KEY } from "config/const.ts";
import "./firebase-init.js";

import timezone from "dayjs/plugin/timezone";
import isToday from "dayjs/plugin/isToday";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import "styles/global.css";
import "config/icon";
import AlertProvider from "contexts/AlertProvider";
import Compose from "contexts/Compose";
import PointProvider from "contexts/PointProvider";
import "dayjs/locale/ko"; // 한국어 로케일 설정

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.locale("ko");
dayjs.tz.setDefault("Asia/Seoul");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

// 안드로이드 PWA에 설치후 최초 진입시 데이터 초기화 로직
// 웹에서 PWA를 다운로드 받고 앱을 실행하면 로그인이 유지되어 있어 알림 허용창을 띄울 수 없기에 데이터 초기화
if (window.matchMedia("(display-mode: standalone)").matches && mobileOS() === "A") {
  const pwaData = localStorage.getItem(STORAGE_KEY.pwaStorageKey);

  if (!pwaData) {
    // PWA가 처음 실행될 때 초기화
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem(STORAGE_KEY.pwaStorageKey, "initialized");
  }
}

const meta = document.createElement("meta");
meta.name = "google";
meta.content = "notranslate";
document.getElementsByTagName("head")[0].appendChild(meta);

root.render(
  <BrowserRouter>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Compose
          components={[
            CookiesProvider,
            ConfirmProvider,
            AlertProvider,
            StackSliderProvider,
            ReportPopupProvider,
            PointProvider,
            TalkPlusClientProvider,
            CategoryUnreadCountProvider,
            ChannelListProvider,
          ]}
        >
          <App />
        </Compose>
      </QueryClientProvider>
    </RecoilRoot>
  </BrowserRouter>
);
