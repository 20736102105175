import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { ChatStatus } from "interfaces/talkplus.interface";
import { EventData } from "talkplus-sdk";
import { useTalkPlusClient } from "./TalkPlusClientContext";
import { getCategoryUnreadCount } from "api/socket.request";
import { useRecoilValue } from "recoil";
import { userState } from "store/user";

type CategoryUnreadCountState = Record<ChatStatus, number> & { total: number };

// Context 타입 정의
interface CategoryUnreadCountContextType {
  categoryUnreadCount: CategoryUnreadCountState;
}

// Context를 생성합니다.
const CateogryUnreadCountContext =
  createContext<CategoryUnreadCountContextType | undefined>(undefined);

const CategoryUnreadCountProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { client, isLoginComplete } = useTalkPlusClient();
  const user = useRecoilValue(userState);

  const [categoryUnreadCount, setCategoryUnreadCount] = useState<CategoryUnreadCountState>({
    [ChatStatus.PENDING]: 0,
    [ChatStatus.IN_PROGRESS]: 0,
    [ChatStatus.COMPLETED]: 0,
    total: 0,
  });

  /**
   * 특정 카테고리에 대한 읽지 않은 메시지 수를 가져와 상태를 업데이트합니다.
   */
  const fetchUnreadCounts = useCallback(async () => {
    if (!client) return;

    try {
      // COMPLETED 상태를 제외
      const statuses = [ChatStatus.PENDING, ChatStatus.IN_PROGRESS];
      const results = await Promise.all(statuses.map((status) => getCategoryUnreadCount(status)));

      // COMPLETED을 제외한 상태의 읽지 않은 메시지 수를 계산
      const updatedCounts = statuses.reduce(
        (acc, status, index) => ({
          ...acc,
          [status]: results[index]?.data?.data?.count || 0,
        }),
        {} as Record<ChatStatus, number>
      );

      // COMPLETED을 제외한 총 메시지 수를 계산
      setCategoryUnreadCount({
        ...updatedCounts,
        total: Object.values(updatedCounts).reduce((sum, count) => sum + count, 0),
      });
    } catch (error) {
      console.error("읽지 않은 메시지 수를 가져오는 데 실패했습니다:", error);
    }
  }, [client]);

  /**
   * 채널 상태가 변경되었을 때 읽지 않은 메시지 수를 다시 가져옵니다.
   */
  const handleChannelChangeEvent = useCallback(
    (data: EventData) => {
      if (data.type === "channelChanged" && data.channel && data.channel.members) {
        fetchUnreadCounts();
      }
    },
    [fetchUnreadCounts]
  );

  /**
   * 클라이언트 이벤트 핸들러를 등록합니다.
   */
  useEffect(() => {
    if (!client) return;

    client.on("event", handleChannelChangeEvent);

    // 컴포넌트가 언마운트되거나 의존성이 변경될 경우 이벤트 핸들러를 제거합니다.
    return () => {
      client.off("event", handleChannelChangeEvent);
    };
  }, [client, handleChannelChangeEvent]);

  /**
   * 로그인 완료 시 초기 데이터를 가져옵니다.
   */
  useEffect(() => {
    if (isLoginComplete) fetchUnreadCounts();
  }, [isLoginComplete, fetchUnreadCounts, user]);

  return (
    <CateogryUnreadCountContext.Provider value={{ categoryUnreadCount }}>
      {children}
    </CateogryUnreadCountContext.Provider>
  );
};

// Context를 사용하는 커스텀 훅을 만듭니다.
export const useCategoryUnreadCount = (): CategoryUnreadCountContextType => {
  const context = useContext(CateogryUnreadCountContext);
  if (!context) {
    throw new Error("provider가 존재해야 함");
  }
  return context;
};

export default CategoryUnreadCountProvider;
