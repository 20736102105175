import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import styled, { css } from "styled-components";
import { daysFormat } from "utils/lib";
import { getNotifications, getNotificationsUnread, patchNotifications } from "api/user.request";
import { IMe, INotification, TNotificationType } from "interfaces/user.interface";
import { Icon, Progress } from "components/shared";
import NotificationItem from "./NotificationItem";

interface IProps {
  user?: IMe | null;
}

const baseURLs: { [key in TNotificationType]: string } = {
  LIVENOTICE: "/broadcast",
  NEWCHAT: "/chat",
  LIVEDETAIL: "/broadcast/detail",
  NEWORDER: "/manage/order",
  NEWFOLLOW: "/manage/follower",
  NEWCOMMENT: "/manage/post",
  NEWREPLY: "/manage/post",
};

const Notification = ({ user }: IProps) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [todos, setTodos] = useState<INotification[]>([]);
  const [page, setPage] = useState(1);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const currentPage = useRef(1);
  const navigate = useNavigate();
  const location = useLocation();

  const { refetch } = useQuery(
    ["notification-count", user, location],
    () => {
      if (!user) return null;
      return getNotificationsUnread().then(({ data }) => {
        if (!data.data) return null;

        if (data.data.unreadCount !== 0) {
          setTodos([]);
          setPage(1);
          setIsEnd(false);
          initialize();
        }

        return data.data.unreadCount;
      });
    },
    {
      onSuccess(response) {
        if (response === null) return;
        setUnreadCount(response);
      },
    }
  );

  const initialize = useCallback(async () => {
    if (page !== 1 && currentPage.current === page) return;

    setIsLoading(true);

    try {
      const { data } = await getNotifications({ page, size: 20 });

      if (!data.data) return;

      const { items, meta } = data.data;

      setIsEnd(meta.isEnd);

      const newTodos = items.map(({ createdAt, ...props }) => ({
        ...props,
        createdAt: daysFormat(createdAt, "YYYY.MM.DD"),
      }));

      setTodos([...todos, ...newTodos]);
      currentPage.current = page;
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [page]);

  const hide = (ev: any) => {
    const target = (ev as React.MouseEvent<HTMLElement>).target as any;
    if (target.id === "more-button") return;
    setToggle(false);
    document.removeEventListener("mousedown", hide);
  };

  const show = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();

    if (toggle) {
      setToggle(false);
      return;
    }

    setToggle(true);
    document.addEventListener("mousedown", hide);
  };

  const moreHandler = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };

  const readHandler = async (props: INotification) => {
    let url = baseURLs[props.type];

    setTodos((prevState) => {
      const readIndex = prevState.findIndex(({ id }) => id === props.id);
      if (readIndex === -1) return prevState;

      prevState[readIndex].unreadCount = 0;
      return prevState;
    });

    // 라이브 예약 관련
    if (props.type === "LIVENOTICE" || props.type === "LIVEDETAIL") {
      if (!props.payload.broadcastId) return;
      url += "/" + props.payload.broadcastId;
    }

    // 채팅
    if (props.type === "NEWCHAT") {
      if (!props.payload.orderChatRoomId) return;
      url += "/" + props.payload.orderChatRoomId;
    }

    // 게시물 댓글 | 대댓글
    if (props.type === "NEWCOMMENT" || props.type === "NEWREPLY") {
      if (!props.payload.postId) return;
      url += "/" + props.payload.postId;
    }

    if (!url) return;

    // 비동기로 실패하더라도 페이지 이동은 이루어져야 한다.
    patchNotifications(props.id).then(() => {
      refetch();
    });

    navigate(url, { replace: true });
  };

  useEffect(() => {
    if (user) {
      initialize();
    }
  }, [user, initialize]);

  return (
    <div className="w-[36px] h-[36px]">
      <div className="relative leading-[36px] text-center cursor-pointer" onMouseDown={show}>
        <Icon icon={["far", "bell"]} fontSize={20} />
        <Pointer show={!!unreadCount}>{unreadCount > 99 ? `99+` : unreadCount}</Pointer>
      </div>
      <Dropdown className="box-shadow" toggle={toggle}>
        <ul className="text-sm py-2">
          {todos.length === 0 && (
            <li className="text-center py-8 text-secondary-900">알림내역이 없습니다.</li>
          )}

          {todos.map((props) => {
            return <NotificationItem key={props.id} {...props} onRead={readHandler} />;
          })}

          {!isEnd && (
            <li className="h-10 flex items-center justify-center text-center">
              {isLoading ? (
                <Progress size={20} />
              ) : (
                <button
                  id="more-button"
                  className="w-full h-full text-primary-900 disabled:text-secondary-400"
                  onClick={moreHandler}
                >
                  더 보기
                </button>
              )}
            </li>
          )}
        </ul>
      </Dropdown>
    </div>
  );
};

const Pointer = styled.span<{ show: boolean }>`
  position: absolute;
  top: 0px;
  left: calc(100% - 18px);
  min-width: 18px;
  height: 18px;
  line-height: 16px;
  border-radius: 10px;
  background-color: #eb2773;
  color: white;
  display: none;
  user-select: none;

  ${(props) =>
    props.show &&
    css`
      display: block;
      font-size: 10px;
    `}
`;

const Dropdown = styled.div<{ toggle: boolean }>`
  position: absolute;
  top: 100%;
  right: 10px;
  width: 300px;
  background-color: white;
  transition: all 0.3s;
  z-index: 50;
  max-height: 360px;
  overflow: auto;

  ${(props) =>
    props.toggle
      ? css`
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
        `
      : css`
          opacity: 0;
          visibility: hidden;
          transform: translateY(-10px);
        `}
`;

export default Notification;
