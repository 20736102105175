const error = {
  "000001": "접근 권한이 없습니다.",
  "000002": "인증 절차에 문제가 발생했습니다.", // 리프래시 토큰이 무효화 되었습니다.
  "000003": "인증 절차에 문제가 발생했습니다.", // 리프래시 토큰 형식이 잘못되었습니다.
  "000004": "인증이 만료되었습니다.", // 리프레시 토큰이 만료되었습니다.
  "000005": "금지된 자원입니다.", // 금지된 자원입니다.
  "100001": "검증되지 않은 폰 번호입니다.",
  "100002": "이미 가입된 이메일 입니다.",
  "100003": "확인 후 다시 시도해주세요",
  "100004": "가입되지 않은 셀러입니다.",
  "100005": "시간 선택이 연속적이지 않습니다.",
  "100006": "이미 등록된 시간입니다.",
  "100007": "시작일이 종료일보다 큽니다.",
  "100008": "방송을 찾을 수 없습니다.",
  "100009": "방송 시작 24시간 전입니다.",
  "100010": "시간 선택이 연속적이지 않습니다.",
  "100011": "방송 시작 10분 전입니다.",
  "100012": "이전 최대 인원 수보다 많지 않습니다.",
  "100013": "예약한 방송을 취소할 수 없습니다.",
  "100014": "게시물이 이미 존재합니다.",
  "100015": "상품이 이미 존재합니다.",
  "100016": "결제 실패했습니다.",
  "100017": "게시물을 찾을 수 없습니다.",
  "100018": "내 게시물이 아닙니다.",
  "100019": "내 댓글이 아닙니다.",
  "100020": "잠시 후 다시 시도해주세요.",
  "100021": "인증번호가 일치하지 않습니다.",
  "100022": "이미 가입된 폰번호 입니다.",
  "100023": "이미 가입된 이름 입니다.",
  "100024": "포인트가 부족합니다.",
  "100025": "상품을 수정할 수 없습니다.",
  "100026": "최대 인원수 정보가 맞지 않습니다.",
  "100027": "포인트 정책이 업데이트 되었습니다.<br/>다시 시도해주세요.",
  "100028": "예약시간 정책이 업데이트 되었습니다.<br/>다시 시도해주세요.",
  "100029": "인증 유효 시간이 초과되었습니다.<br/>다시 시도해주세요.",
  "100030": "내 방송이 아닙니다.",
  "100031": `
  계좌정보가 설정되지 않았습니다. <br/>
  고객센터로 문의해주세요`,
  "100032": "단골 수가 50명 미만입니다.",
  "100033": "계정 활성 승인되면 안내드리겠습니다.",
};

export default error;
