import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useQueryClient, useInfiniteQuery, InfiniteData } from "react-query";
import { useTalkPlusClient } from "contexts/TalkPlusClientContext";
import { getChannels } from "api/socket.request";
import dayjs from "dayjs";
import { CustomChannelInfo, ChatStatus } from "interfaces/talkplus.interface";
import { Member, Channel } from "talkplus-sdk";
import { RefetchOptions } from "react-query";
import { QueryObserverResult } from "react-query";
import { EventData } from "talkplus-sdk";
import { useRecoilValue } from "recoil";
import { userState } from "store/user";

// Context 타입 정의
interface ChannelListContextType {
  data:
    | InfiniteData<{
        hasNext: boolean;
        channels: CustomChannelInfo[];
        cursor?: string;
      }>
    | undefined;
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isLoading: boolean;
  removeChannel: (channelId: string) => Promise<void>;
  addChannel: (updateChannel: CustomChannelInfo, channelId: string) => void;
  customChannelList: (channel: CustomChannelInfo | Channel) => CustomChannelInfo;
  isFirstLoading: boolean;
  isFetchingNextPage: boolean;
  handleFilterStatus: (status: ChatStatus) => void;
  filterStatus: ChatStatus;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<any, unknown>>;
}

// Context 생성
export const ChannelListContext = createContext<ChannelListContextType | null>(null);

// Provider 컴포넌트
const ChannelListProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { client, isLoginComplete } = useTalkPlusClient();
  const queryClient = useQueryClient();
  const user = useRecoilValue(userState);
  const [filterStatus, setFilterStatus] = useState<ChatStatus>(ChatStatus.PENDING);
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);

  /**
   * 채널 데이터를 커스텀 형식으로 변환
   * - 서버에서 받은 채널 데이터를 UI에 필요한 형식으로 가공
   */
  const customChannelList = (channel: CustomChannelInfo | Channel): CustomChannelInfo => {
    if ("id" in channel) {
      const KSTSentAt = dayjs(channel.lastSentAt).tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss");
      const guest = channel.members?.find((member) => member.data?.role === "GUEST") as Member;
      const seller = channel.members?.find((member) => member.data?.role === "SELLER") as Member;

      return {
        category: channel.category as ChatStatus,
        chatRoomId: channel.id,
        content: channel.lastMessage?.data?.imgURL ? "이미지" : channel.lastMessage?.text || "",
        guestId: Number(guest?.id || 0),
        guestName: guest?.username || "알 수 없음",
        guestProfileUrl: guest?.profileImageUrl || "",
        rawSentAt: String(channel.lastSentAt),
        rawUpdatedAt: String(channel.updatedAt),
        sellerId: Number(seller?.id || 0),
        sellerName: seller?.username || "알 수 없음",
        sellerProfileUrl: seller?.profileImageUrl || "",
        sentAt: KSTSentAt,
        unreadCount: channel.unreadCount || 0,
        formattedSentAt: formatSentAt(KSTSentAt),
      };
    }

    const timestampInSeconds = Number(channel.rawSentAt) / 1000;
    const KSTSentAt = dayjs(timestampInSeconds * 1000)
      .tz("Asia/Seoul")
      .format("YYYY-MM-DD HH:mm:ss");

    return {
      ...channel,
      sentAt: KSTSentAt,
      formattedSentAt: formatSentAt(KSTSentAt),
    };
  };

  /**
   * 필터 상태 변경 핸들러
   * - 채팅 상태 필터를 변경
   */
  const handleFilterStatus = (status: ChatStatus) => setFilterStatus(status);

  /**
   * 채널 목록 데이터 불러오기
   * - 서버로부터 채널 데이터를 페이징으로 요청
   */
  const fetchChannels = async ({ pageParam }: { pageParam?: string | null }) => {
    if (!isLoginComplete) return { hasNext: false, channels: [] };
    const res = await getChannels({
      size: 100,
      category: filterStatus,
      next: pageParam || null,
    });

    if (isFirstLoading) setIsFirstLoading(false);

    if (!res.data?.data) throw new Error("채널 데이터를 불러오는 중 오류 발생");

    const customChannels = res.data.data.items.map(customChannelList);

    return {
      hasNext: !res.data.data.meta.isEnd,
      channels: customChannels,
      cursor:
        filterStatus === ChatStatus.COMPLETED
          ? customChannels.at(-1)?.rawUpdatedAt
          : customChannels.at(-1)?.rawSentAt,
    };
  };

  // React Query: Infinite Query를 활용한 데이터 페이징
  const { data, fetchNextPage, hasNextPage, refetch, isLoading, isFetchingNextPage } =
    useInfiniteQuery(["chat-list", user, filterStatus], fetchChannels, {
      getNextPageParam: (lastPage) => (lastPage?.hasNext ? lastPage.cursor : null),
      // enabled: isLoginComplete,
      enabled: false,
    });

  useEffect(() => {
    if (isLoginComplete) refetch(); // filterStatus 변경 후 수동 요청
  }, [filterStatus, isLoginComplete, user]);

  const handleEvent = useCallback(
    async (data: EventData) => {
      if (data.type === "channelChanged" && data.channel && data.channel.members) {
        await refetch();
      }
    },

    [queryClient, user, filterStatus]
  );

  useEffect(() => {
    client.on("event", handleEvent);

    return () => {
      client.off("event", handleEvent); // 여기서 반환 값이 없음
    };
  }, [client, handleEvent]);

  /**
   * 채널 추가
   * - 새 채널 데이터를 기존 목록에 추가
   */
  const addChannel = (updateChannel: CustomChannelInfo, channelId: string) => {
    queryClient.setQueryData(["chat-list", user, filterStatus], (oldData: any) => {
      if (!oldData) return;

      const updatedPages = [
        {
          ...oldData.pages[0],
          channels: [
            updateChannel,
            ...oldData.pages[0].channels.filter((channel: any) => channel.chatRoomId !== channelId),
          ],
        },
        ...oldData.pages.slice(1).map((page: any) => ({
          ...page,
          channels: page.channels.filter((channel: any) => channel.chatRoomId !== channelId),
        })),
      ];

      return { ...oldData, pages: updatedPages };
    });
  };

  useEffect(() => {
    setFilterStatus(ChatStatus.PENDING);
  }, [user]);

  /**
   * 채널 제거
   * - 지정된 ID의 채널을 목록에서 삭제
   */
  const removeChannel = async (channelId: string) => {
    queryClient.setQueryData(["chat-list", user, filterStatus], (oldData: any) => {
      if (!oldData) return;

      const updatedPages = oldData.pages.map((page: any) => ({
        ...page,
        channels: page.channels.filter((channel: any) => channel.chatRoomId !== channelId),
      }));

      const nonEmptyPages = updatedPages.filter((page: any) => page.channels.length > 0);
      return { ...oldData, pages: nonEmptyPages };
    });

    await queryClient.fetchQuery(["chat-list", user, filterStatus]);
  };

  return (
    <ChannelListContext.Provider
      value={{
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        removeChannel,
        addChannel,
        customChannelList,
        isFirstLoading,
        isFetchingNextPage,
        handleFilterStatus,
        filterStatus,
        refetch,
      }}
    >
      {children}
    </ChannelListContext.Provider>
  );
};

// Context 소비를 위한 커스텀 훅
export const useChannelList = (): ChannelListContextType => {
  const context = useContext(ChannelListContext);
  if (!context) {
    throw new Error("ChannelListProvider 내에서만 useChannelList를 사용할 수 있습니다.");
  }
  return context;
};

export default ChannelListProvider;

/**
 * 전송 시간 포맷팅 함수
 * - 날짜를 한국어 형식으로 변환
 */
const formatSentAt = (sentAt: string): string => {
  const now = dayjs();
  const date = dayjs(sentAt);

  if (date.isSame(now, "day")) {
    const hour = date.hour();
    const minute = date.minute();
    const period = hour < 12 ? "오전" : "오후";
    const hour12 = hour % 12 || 12;
    return `${period} ${hour12}:${String(minute).padStart(2, "0")}`;
  } else if (date.isSame(now.subtract(1, "day"), "day")) {
    return "어제";
  } else if (date.isSame(now, "year")) {
    return date.format("M.DD (ddd)");
  } else {
    return date.format("YYYY.M.DD (ddd)");
  }
};
