import { Member } from "talkplus-sdk";

export interface CustomChannelInfo {
  category: ChatStatus;
  chatRoomId: string;
  content: string;
  guestId: number;
  guestName: string;
  guestProfileUrl: string | null;
  rawSentAt: string;
  rawUpdatedAt: string;
  sellerId: number;
  sellerName: string;
  sellerProfileUrl: string | null;
  sentAt: string;
  unreadCount: number;
  formattedSentAt: string;
}

// 채널 상태 enum
export enum ChatStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

// 댓글 전송 상태
export enum CommentStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  FORBIDDEN = "FORBIDDEN",
}

// 댓글 타입
export enum CommentType {
  TEXT = "TEXT",
}

// 채널 업데이트 데이터
export interface UpdateChannelData {
  category?: ChatStatus;
}

// 검색 기록
export interface ChatHistory {
  id: number;
  name: string;
  unreadCount: number;
  lastMessage: string;
  category: ChatStatus;
}

// 금칙어 사용 데이터
export interface OffensiveData {
  chatRoomId: string;
  sendUserId: number;
  receiveUserId: number;
  profileUrl: string;
  nickname: string;
  content: string;
  sentAt: number;
}

// 메세지 형식 통합
export interface CustomComment {
  id: string;
  type: CommentType;
  text: string;
  createdAt: number;
  userId: string;
  channelId: string;
  username: string;
  data: {
    imageURL: string;
  };
  status: CommentStatus;
}

// 임의로 채팅 전송시 전달하는 페이로드
export interface ChatPayload {
  id: string;
  text: string;
  type: CommentType;
  data: {
    imageURL: string;
  };
  status: CommentStatus;
}

// 클메 서버에서 주는 멤버 정보
export type CMMember = {
  id: string;
  username: string;
  profileImageUrl: string;
  data: {
    role: string;
    phone: string;
  };
};

// 클메 서버의 채널 정보
export type CMChannel = {
  id: string;
  category: string;
  members: CMMember[];
};

// 클메 서버 채널정보 커스텀
export type CustomChannel = {
  id: string;
  category: string;
  seller: Member;
  guest: Member;
};
