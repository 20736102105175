import Placeholder from "../../assets/placeholder.png";

interface IProps {
  src?: string | null;
  size?: number;
  defaultSrc?: string;
}

const Thumbnail = ({ src, size = 36, defaultSrc }: IProps) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // 에러 발생 시 defaultSrc가 있으면 교체, 없으면 Placeholder 사용
    event.currentTarget.src = defaultSrc || Placeholder;
  };

  return (
    <div
      style={{ width: size, height: size, borderRadius: size / 2.5 }}
      className="overflow-hidden border border-secondary-200"
    >
      <img
        className="object-cover object-center w-full h-full"
        src={src || Placeholder}
        alt="썸네일"
        onError={handleImageError} // onError 핸들러 추가
      />
    </div>
  );
};

export default Thumbnail;
