import { useEffect, useState } from "react";
import { mobileOS } from "utils/lib";
import { useLocation } from "react-router-dom";

// 외부 브라우저 모달 컴포넌트
const ExternalBrowserModal = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const osType = mobileOS();
    const userAgent = navigator.userAgent;

    if (!osType) return;

    // 카카오톡 인앱일경우 바로 외부 브라우저로 열기
    if (/KAKAOTALK/i.test(userAgent)) {
      const currentUrl = window.location.href;
      const redirectUrl = `kakaotalk://web/openExternal?url=${encodeURIComponent(currentUrl)}`;
      window.location.href = redirectUrl;
      return;
    }

    // 인스타그램 인앱에서 접속 시 외부 브라우저 이동 모달 표시
    if (/Instagram/i.test(userAgent)) {
      setIsVisible(true);
    }
  }, [location]);

  if (!isVisible) return null; // 모달 숨김 처리

  const handleClose = () => setIsVisible(false);

  return (
    <div className="fixed inset-0 flex items-end justify-center bg-[rgba(34,34,34,0.325)] z-[1000]">
      <div className="w-[100%] p-[40px_20px] bg-white rounded-tl-[20px] rounded-tr-[20px] flex flex-col gap-4 relative">
        <button
          className="absolute top-[10px] right-[10px] text-[20px] text-[grey] w-[20px] h-[20px] flex items-center justify-center bg-white border-none p-0 font-bold"
          onClick={handleClose}
        >
          &times;
        </button>

        <h5 className="text-[16px] text-gray-600 text-center line-height-[1.5">
          클릭메이트의 원활한 서비스를 위해 <br /> 외부 브라우저를 사용해 주세요
        </h5>


        {/Instagram/i.test(navigator.userAgent) && (
          <img
            src={`/img_guide_open_browser_ios.png`}
            alt="인스타그램 외부 브라우저로 이동 가이드 이미지"
          />
        )}
      </div>
    </div>
  );
};

export default ExternalBrowserModal;
