import firebase from "firebase/app";
import "firebase/messaging";
import env from "config/env";
import { mobileOS } from "utils/lib";

const firebaseConfig = {
  apiKey: env.FIREBASE.API_KEY,
  authDomain: env.FIREBASE.AUTH_DOMAIN,
  projectId: env.FIREBASE.PROJECT_ID,
  storageBucket: env.FIREBASE.STORAGE_BUCKET,
  messagingSenderId: env.FIREBASE.MEASUREMENT_ID,
  appId: env.FIREBASE.APP_ID,
};

// 로컬 스토리지에서 'pushToken' 키 제거
localStorage.removeItem("pushToken");

// Firebase 초기화
firebase.initializeApp(firebaseConfig);

let messaging; // Firebase 메시징 인스턴스 변수

const os = mobileOS(); // 현재 모바일 OS 확인
const isPWA = window.matchMedia("(display-mode: standalone)").matches; // PWA 실행 여부 확인

// Firebase 메시징 지원 여부, 모바일 OS, PWA 실행 여부에 따라 메시징 초기화
if (firebase.messaging.isSupported() && os && isPWA) {
  messaging = firebase.messaging();
}

// 문서 상태가 변경될 때 서비스 워커 등록 및 준비 처리
document.onreadystatechange = async () => {
  if ("serviceWorker" in navigator && document.readyState === "complete") {
    try {
      // 서비스 워커 등록
      const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
      console.log("서비스 워커가 등록되었습니다:", registration);

      // 서비스 워커 준비될 때까지 대기
      const readyRegistration = await navigator.serviceWorker.ready;
      console.log("서비스 워커가 준비되었습니다:", readyRegistration);
    } catch (error) {
      console.error("서비스 워커 등록에 실패했습니다:", error);
    }
  }
};

// PWA로 실행 중일 경우 테마 색상 변경
if (isPWA) {
  document.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#EF528F");
}

async function getDeviceToken() {
  // 권한이 허용된 후에 토큰을 가져옴
  const token = messaging.getToken(messaging, {
    vapidKey: env.FIREBASE.VAPID_KEY,
  });

  return token;
}

export { getDeviceToken };
