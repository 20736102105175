// TalkPlusClientContext.tsx
import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { Client } from "talkplus-sdk"; // TalkPlus 라이브러리 import
import env from "config/env";
import { getChannel, getTalkPlusLoginToken } from "api/socket.request";
import { useRecoilValue } from "recoil";
import { userState } from "store/user";
import { postChatRead } from "api/socket.request";
import { ChatStatus } from "interfaces/talkplus.interface";

// TalkPlus.Client 인스턴스를 생성합니다.
const client = new Client({ appId: env.TALK_PLUS_APP_ID });

// Context 타입 정의
interface TalkPlusClientContextType {
  client: Client;
  isLoginComplete: boolean;
  isPageVisible: boolean; // 페이지 가시성 여부를 추가
  markAsRead: (channelId: string) => void;
}

// Context를 생성합니다. 초기값은 클라이언트 인스턴스입니다.
const TalkPlusClientContext = createContext<TalkPlusClientContextType | undefined>(undefined);

const TalkPlusClientProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [isLoginComplete, setIsLoginComplete] = useState<boolean>(false);
  const [isPageVisible, setIsPageVisible] = useState<boolean>(
    document.visibilityState === "visible"
  ); // 초기값 설정
  const user = useRecoilValue(userState);

  // 페이지 가시성 상태를 감지하는 useEffect
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsPageVisible(document.visibilityState === "visible");
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const markAsRead = useCallback(
    async (channelId: string) => {
      try {
        // 로그인 중이 아니거나, 채널을 보고있지 않다면 메세지를 읽지 않음

        if (!isLoginComplete || !isPageVisible) return;

        // 클메 서버에서 채널 데이터를 받아옴
        const channelRes = await getChannel(channelId);
        // 채널 데이터가 없거나, 채널 카테고리가 대기일 경우 읽지 않음
        if (!channelRes.data.data || channelRes.data.data?.category === ChatStatus.PENDING) return;

        postChatRead(channelId); // 채팅 읽음 처리 API 호출
        client.markChannelAsRead({ channelId }); // TalkPlus 클라이언트에서 읽음 처리
      } catch (err) {
        console.log("읽음처리 실패", err);
      }
    },
    [isLoginComplete, isPageVisible]
  );

  // 리스트 페이지 진입시 첫 1회 로그인 진행
  useEffect(() => {
    const fetchAndLogin = async () => {
      if (!user) return;

      try {
        const response = await getTalkPlusLoginToken();

        // 데이터 구조에 따른 타입 정의
        const { data } = response.data;
        if (!data) {
          throw new Error("서버에서 데이터를 안 줬습니다.");
        }

        const { loginToken, user } = data;
        // 로그인 처리
        if (user && loginToken) {
          await client.loginWithToken({
            userId: user.id,
            loginToken,
          });

          setIsLoginComplete(true);
        }
      } catch (err) {
        console.log(err);
        // 에러 처리
        console.error("로그인 실패함", err);
      }
    };

    fetchAndLogin();
  }, [user]);

  return (
    <TalkPlusClientContext.Provider value={{ client, isLoginComplete, isPageVisible, markAsRead }}>
      {children}
    </TalkPlusClientContext.Provider>
  );
};

// Context를 사용하는 커스텀 훅을 만듭니다.
export const useTalkPlusClient = (): TalkPlusClientContextType => {
  const context = useContext(TalkPlusClientContext);
  if (!context) {
    throw new Error("provider가 존재해야 함");
  }
  return context;
};

export default TalkPlusClientProvider;
