import { ChatStatus } from "interfaces/talkplus.interface";

export const STORAGE_KEY = {
  refreshTokenKey: "_sq_rtoken",
  pwaStorageKey: "pwa_storage",
  pushToken: "push_token",
  firstAlarmConfirm: "first_alarm_confirm",
  chatListScrollY: "chat_list_scrollY",
  chatListFilterStatus: "chat_list_filter_status",
  isTokenChecked: "is_token_checked",
  searchWord: "search_word",
};

export const STATUS_KOREAN: Record<ChatStatus, string> = {
  [ChatStatus.PENDING]: "대기",
  [ChatStatus.IN_PROGRESS]: "진행중",
  [ChatStatus.COMPLETED]: "완료",
};
