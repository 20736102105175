import axios, { AxiosError } from "axios";
import env from "config/env";
import errorPackage from "./error.pkg";
import { STORAGE_KEY } from "config/const";

const request = axios.create({
  baseURL: env.BASE_URL,
});

export class ErrorHandler extends Error {
  constructor(public code: keyof typeof errorPackage, public message: string) {
    super();
  }
}

request.interceptors.request.use(
  function (request) {
    // 로컬 스토리지에서 토큰 가져오기
    const token = localStorage.getItem(STORAGE_KEY.refreshTokenKey);

    // 토큰이 존재하면 Authorization 헤더에 추가
    if (token && request.headers) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }

    return request;
  },
  function (err) {
    throw new Error(err);
  }
);

request.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err: AxiosError<ErrorData | undefined | null>) {
    const { response } = err;

    if (!response || !response.data) {
      throw new Error("알 수 없는 오류가 발생했습니다.<br/>다시 시도해주세요.");
    }

    const { code } = response.data;

    if (err.message === "Network Error") {
      throw new Error("알 수 없는 오류가 발생했습니다.<br/>다시 시도해주세요.");
    }

    if (errorPackage[code]) {
      const error = new ErrorHandler(code, errorPackage[code]);
      throw error;
    }

    /**
     *  code: "정의되지 않은 에러입니다. 백엔드 개발자에게 문의해주세요."
     *  error: "No Matched Code"
     *  message: "인증번호가 일치하지 않습니다."
     *  status: 409
     */

    throw new Error("알 수 없는 오류가 발생했습니다.<br/>다시 시도해주세요.");
  }
);

export const setHeader = (token: string) => {
  request.defaults.headers.common["Authorization"] = token;
};

export interface ErrorData {
  code: keyof typeof errorPackage;
  error: string;
  message: string;
  status: number;
}

export interface Response<T> {
  data?: T;
  message?: string;
  status: number;
}

export type Data<T, K = any> = {
  items: T;
  meta: {
    isEnd: boolean;
    pageableCount: number;
    totalCount: number;
  };
} & K;

export interface TodoResponse<T, K = {}> {
  data?: Data<T, K>;
  message?: string;
  status: number;
}

export default request;
