import { AxiosPromise } from "axios";
import env from "config/env";
import { SeverTalkPlusUser } from "interfaces/guest.interface";
import { IOpponent, IOrderComment, IOrderMessageData } from "interfaces/order.interface";
import { UpdateChannelData } from "interfaces/talkplus.interface";
import { Channel } from "talkplus-sdk";
import {
  CMChannel,
  ChatHistory,
  ChatStatus,
  CustomChannelInfo,
  OffensiveData,
} from "./../interfaces/talkplus.interface";
import request, { Response, TodoResponse } from "./config";
const BASE_URL = env.CS_SOCKET_URL;

export const getTalkPlusLoginToken = () =>
  request({ baseURL: BASE_URL, url: "/talkplus/auth/login", method: "POST" }) as AxiosPromise<
    Response<SeverTalkPlusUser>
  >;

// FCM 토큰 등록 함수
export const postFCMToken = (token: string, os: string) =>
  request({
    baseURL: BASE_URL,
    url: "/pushes/fcm",
    method: "POST",
    data: {
      token,
      os,
    },
  });

// FCM 토큰 등록 해제 함수
export const deleteFCMToken = () =>
  request({
    baseURL: BASE_URL,
    url: "/pushes/fcm",
    method: "DELETE",
  });

type PostDataType = {
  message: {
    type: "IMAGE" | "TEXT";
    body: string;
  };
  additionalData: {
    chatRoomId: string;
    clickId: string;
    sendUserName: string;
    profileUrl: string;
    fileUrl: string;
    talkplusMessageId: string;
    createdAt: number;
  };
};

// 메세지 알림 전송 함수
export const postSendAlarm = (userId: string, data: PostDataType) =>
  request({
    baseURL: BASE_URL,
    url: `/chat/users/${userId}`,
    method: "POST",
    data,
  });

// 채널 생성 함수
export const createRoom = (guestId: number, sellerId: number) =>
  request({
    baseURL: BASE_URL,
    url: "/rooms",
    method: "POST",
    data: {
      guestId,
      sellerId,
    },
  }) as AxiosPromise<Response<{ orderChatRoomId: number }>>;

export const getOrderMessage = (roomId: string, params: { size: number; [key: string]: any }) =>
  request({
    baseURL: BASE_URL,
    url: `/rooms/${roomId}/messages`,
    method: "GET",
    params,
  }) as AxiosPromise<TodoResponse<IOrderComment[], { cursor: string }>>;

export const postOrderMessage = (roomId: string, data: IOrderMessageData) =>
  request({
    baseURL: BASE_URL,
    url: `/rooms/${roomId}/messages`,
    method: "POST",
    data,
  });

export const getChatPartners = (roomId: string) =>
  request({
    baseURL: BASE_URL,
    url: `/rooms/${roomId}/users`,
    method: "GET",
  }) as AxiosPromise<Response<IOpponent[]>>;

// 메세지 읽음처리 함수
export const postChatRead = (roomId: string | number) =>
  request({
    baseURL: BASE_URL,
    url: `/chat/rooms/${roomId}/read`,
    method: "POST",
  });

// 유저 초대 함수 채널에 게스트 혹은 셀러가 없을 경우 초대할 때 사용
export const inviteUser = (roomId: string | number, userId: string) =>
  request({
    baseURL: BASE_URL,
    url: `/chat/rooms/${roomId}/users/${userId}/invite`,
    method: "POST",
  }) as AxiosPromise<Response<{ channel: Channel }>>;

// 채널 정보 업데이트 함수
export const updateChannel = (roomId: string | number, data: UpdateChannelData) =>
  request({
    baseURL: BASE_URL,
    url: `/chat/rooms/${roomId}`,
    method: "PATCH",
    data,
  });

// 셀러와의 채팅 기록을 검색으로 가져오는 함수
export const getChatHistory = (searchWord: string) =>
  request({
    baseURL: BASE_URL,
    url: "/chat/guests",
    method: "GET",
    params: {
      searchWord: searchWord,
    },
  }) as AxiosPromise<Response<{ items: ChatHistory[] }>>;

// 셀러의 채팅 리스트 가져오는 함수
export const getChannels = ({
  size,
  category,
  next,
}: {
  size: number;
  category: ChatStatus;
  next: string | null;
}) =>
  request({
    baseURL: BASE_URL,
    url: "/chat/rooms",
    method: "GET",

    params: {
      next: next || "",
      size,
      category,
    },
  }) as AxiosPromise<
    Response<{
      items: CustomChannelInfo[];
      meta: {
        isEnd: boolean;
      };
    }>
  >;

// 금칙어 서버 기록 함수
export const postOffensive = (data: OffensiveData) =>
  request({
    baseURL: BASE_URL,
    url: "/chat/offensive",
    method: "POST",
    data,
  });

export const getCategoryUnreadCount = (category: ChatStatus) =>
  request({
    baseURL: BASE_URL,
    url: "/chat/rooms/unread/count",
    method: "GET",
    params: {
      category,
    },
  }) as AxiosPromise<
    Response<{
      count: number;
    }>
  >;

export const getChannel = (channelId: string) =>
  request({
    baseURL: BASE_URL,
    url: `chat/rooms/${channelId}`,
    method: "GET",
    params: {
      chatRoomId: channelId,
    },
  }) as AxiosPromise<Response<CMChannel>>;
